* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
}

p,
a,
li,
span,
button,
label,
tr,
td {
  font-family: "Open Sans", sans-serif;
  /* font-family: "Work Sans", sans-serif; */
}

h1 {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0;
}

h2 {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0;
}

h3 {
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
}

h5 {
  color: #00a5dd;
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}

p {
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: 300;
}

/* .............................button style............................ */
.btn-danger {
  --red-shade: var(--red-shade, #eb3a56);
}

.btn-warning {
  --logo-yellow: var(--logo-yellow, #f5aa30);
}

.btn-primary {
  background-color: #00a5dd;
}

.btn-primary:hover {
  background-color: #0290bf;
}

.button1 {
  background: #f5aa30;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  height: 40px;
  padding: 11px 21px 12px 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  z-index: 9999;
}

.button1:hover {
  background: #df9a2b;
  color: #fff;
  -webkit-transition: ease-in-out 0.4s;
  -o-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
}

.card:hover {
  -webkit-box-shadow: 1px 8px 20px rgb(129, 129, 129);
  box-shadow: 1px 8px 20px rgb(129, 129, 129);
  -webkit-transition: -webkit-box-shadow 0.4s ease-in;
  transition: -webkit-box-shadow 0.4s ease-in;
  -o-transition: box-shadow 0.4s ease-in;
  transition: box-shadow 0.4s ease-in;
  transition: box-shadow 0.4s ease-in, -webkit-box-shadow 0.4s ease-in;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #f5aa30;
}

form input:focus-visible,
form textarea:focus-visible {
  outline: 1px solid #f5aa30 !important;
  color: #f5aa30 !important;
}

.slick-prev,
.slick-next {
  z-index: 999;
}

.slick-prev {
  left: -16px;
}

.slick-next {
  right: -5px;
}

.slick-prev::before,
.slick-next:before {
  font-size: 35px;
}

.certified .slick-prev {
  left: -9px;
}

.certified .slick-next {
  right: 3px;
}

.slick-dots {
  display: none !important;
}

/*..........................Swal..............................*/
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 9999999;
}

/*..........................Loading css.........................*/
.loader {
  width: 50px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: -o-radial-gradient(farthest-side, #cdcbcb 94%, #54545400) top/8px 8px no-repeat,
    conic-gradient(#92929200 30%, #cdcbcb);
  background: radial-gradient(farthest-side, #cdcbcb 94%, #4e4d4d00) top/8px 8px no-repeat,
    conic-gradient(#7b7a7a00 30%, #cdcbcb);
  -webkit-mask: radial-gradient(farthest-side,
      #84848400 calc(100% - 8px),
      #919090 0);
  -webkit-animation: l13 1s infinite linear;
  animation: l13 1s infinite linear;
}

@-webkit-keyframes l13 {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes l13 {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.navbar-toggler {
  padding: 1px 8px;
  background: #fff;
}

.navbar-nav .dropdown-menu {
  border: none;
}

.navbar-nav .dropdown-menu a {
  margin-bottom: 0.2rem;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #f5aa30;
}

/* ....................Courselist..................... */
.courselist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  height: auto;
}

.courselist li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  padding-top: 0.2rem;
  cursor: pointer;
  width: 285px;
}

.courselist li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.courselist li:hover {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.courselist li p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0;
}

.courselist img {
  width: 2rem;
  height: 1.5rem;
  padding-right: 4px;
  -o-object-fit: contain;
  object-fit: contain;
}

.usefullink ul li {
  list-style: none;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0;
  padding-top: 0.2rem;
  cursor: pointer;
  width: 285px;
  height: 35px;
}

.navbar-nav .dropdown .all {
  width: 92.5rem;
  height: 33rem;
  right: auto;
  left: auto;
  top: 2rem;
  -webkit-transform: translate(-18%, 0);
  -ms-transform: translate(-18%, 0);
  transform: translate(-18%, 0);
  -o-transform: "translate";
  padding: 0;
  background: transparent;
}

.navbar-nav .dropdown .all-co {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.navbar-nav .dropdown .courses {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 0 4px 4px 0;
}

.navbar-nav .dropdown .courses {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 0 4px 4px 0;
}

.alldrop {
  position: relative;
}

.navbar-nav .dropdown .all row {
  margin: auto;
  position: absolute;
  z-index: 999;
}

.navbar-nav .dropdown ul li a {
  color: #000;
}

.about-li a {
  color: #000 !important;
}

.about-li {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.76);
  height: 200px;
  width: 15rem;
  -webkit-animation: slideup 0.5s ease-in-out;
  animation: slideup 0.5s ease-in-out;
}

@-webkit-keyframes slideup {
  from {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideup {
  from {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.search-modal .modal-content {
  -webkit-animation: grow 0.8s ease;
  animation: grow 0.8s ease;
}

@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes grow {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.about-li a {
  letter-spacing: 0;
  line-height: 30px;
}

.navbar-nav .dropdown .all h5 {
  margin: 1rem 0 1.5rem 2rem;
  color: #3e3d3d;
}

.navbar-nav .dropdown .all .categories h6 {
  margin: 1.5rem 0 2.5rem 1rem;
  color: #ffff;
}

.navbar-nav .dropdown .all .categories {
  height: 33rem;
  border-radius: 4px 0 0 4px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 165, 221, 0.69);
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding-right: 0;
}

.navbar-nav .dropdown .all .categories button {
  padding: 10px;
}

.navbar-nav .dropdown .all .categories .dropdown-item {
  color: #ffff;
}

.navbar-nav .dropdown .all .categories .dropdown-item:hover,
.navbar-nav .dropdown .all .categories .dropdown-item:focus {
  color: #000;
}

.dropdown .nav-item a {
  text-decoration: none;
  color: var(--white, #fff);
  text-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.08);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
}

.navbar-nav .dropdown-menu a {
  color: #000;
}

.search-box {
  border: 2px solid #868686;
}

.search-regi .button1 {
  font-size: 15px;
}

.search-btn {
  color: #fff;
  float: right;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 20px;
}

.search-box {
  border-radius: 5px;
}

.search-text {
  border: none;
  outline: none;
  float: left;
  padding: 0;
  font-size: 1rem;
  font-weight: normal;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  line-height: 40px;
  width: 100%;
  background: transparent;
  padding-left: 8px;
}

.search-result {
  position: absolute;
  top: 5rem;
  display: none;
  z-index: 9999;
  left: 0;
  height: 100%;
  width: 100%;
}

.search-result ul {
  height: 85%;
  overflow-y: scroll;
  padding-left: 0;
  margin-bottom: 0;
}

.search-result ul a {
  text-decoration: none;
}

.search-result ul li {
  padding: 10px 26px;
  list-style: none;
  color: #000;
}

.search-result ul li img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 8px;
}

.search-result ul button {
  position: absolute;
  left: 75%;
  top: -35px;
  border: none;
  background: transparent;
  cursor: pointer;
}

/*...........................Search Modal.......................*/
.modal {
  -webkit-backdrop-filter: brightness(0.5);
  backdrop-filter: brightness(0.5);
  z-index: 999990;
}

.search-modal .modal-dialog {
  max-width: 100%;
}

.search-modal .modal-content {
  /* height: 60vh; */
  width: 70vw;
  margin: 2rem auto;
  overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.79);
}

.search-modal .modal-head {
  padding-bottom: 2rem;
}

.search-modal .modal-content span {
  font-size: 25px;
  text-align: right;
  padding-right: 1rem;
  cursor: pointer;
}

.search-modal .modal-content .search-box {
  width: 45vw;
  margin: auto;
}

.search-modal .modal-content .search-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-modal .modal-content form .search-text {
  width: 100%;
}

/*......................Common Contact Form.....................*/
form .form-clip {
  clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
  background-color: #00a5dd;
  padding: 15px 0;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

form {
  margin: auto;
  border: 2px solid #1DA7F5;
  position: sticky;
  top: 7.3rem;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

form .formdiv {
  padding: 20px 20px 35px 20px;
}

form h3 {
  color: #fff;
  text-align: center;
}

form .under-div {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

form h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
}

form .err {
  margin-bottom: 0;
  color: red !important;
}

form .formdiv label {
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

form label {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 0 15px 12px 8px;
}

form .formradio label {
  padding-bottom: 0;
}

.formradio select {
  margin-top: 0 !important;
}

form .perinfo input,
form .perinfo textarea {
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  padding: 10px;
  padding: 8px 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

form .name {
  gap: 10px;
}

form .select select {
  color: var(--text-subtle, #8c8ca1);
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

form .confirmbox .note {
  color: #414141;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

form .confirm label {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
}

form .confirmbox button {
  border-radius: 3px;
  background: #f5aa30;
  border: none;
  color: #fff;
  width: 32%;
  padding: 10px 0;
  width: 100%;
  white-space: nowrap;
}

form .confirmbox button:hover {
  background: #e79d26;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
  color: #fff;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

/* ............................Tophead......................... */
.tophead {
  background: #f5aa30;
  position: fixed;
  width: 100%;
  z-index: 99999;
}

.tophead .head {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tophead p {
  color: var(--text, #fff);
  margin-top: 0.3rem;
  padding-bottom: 0.29rem;
  margin-bottom: 0;
}

.tophead .call {
  color: var(--text, #fff);
  font-size: 1rem;
  text-decoration: none;
}

.tophead a {
  color: #00a5dd;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 140%;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.service-drop {
  width: 35rem;
  height: 370px;
  padding: 0;
  background: transparent;
  -webkit-animation: slideup 0.5s ease-in-out;
  animation: slideup 0.5s ease-in-out;
}

@keyframes slideup {
  from {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.service-drop .left-side {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 165, 221, 0.69);
  border-radius: 4px 0 0 4px;
  padding-right: 0;
}

.service-drop .left-side h5 {
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.04rem;
  margin-bottom: 1rem;
}

.service-drop .left-side button {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0375rem;
  background: transparent;
  border: none;
  margin-bottom: 0.5rem;
  padding: 8px 5px;
  width: 100%;
  text-align: left;
}

.service-drop .right-side {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 0 4px 4px 0;
}

.service-drop .right-side ul li {
  list-style: none;
}

.service-drop .right-side ul li a {
  letter-spacing: 0;
  line-height: 30px;
  font-size: 15px;
}

.service-drop .left-side .activee {
  background-color: #fff;
  color: #000;
}

/* ..........................Navbar......................... */
.navbar {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(69, 69, 69, 0.62);
}

.menubg {
  position: fixed;
  top: 2.2rem;
  width: 100%;
  z-index: 99999;
}

.navbar .navbar-brand {
  margin-left: 2.5rem;
}

.navbar .search-regi {
  margin-right: 2.5rem;
}

.navbar .navbar-brand img {
  width: 12rem;
}

.navbar .navbar-nav li a {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.navbar .navbar-nav li a:hover,
.navbar-nav .dropdown .all-co:hover {
  color: #F5AA30;
}

.navbar2 img {
  width: 12rem;
}

.navbar2 .allcourse {
  margin: 0 28px;
}

.navbar2 .allcourse button {
  padding: 10px 20px;
  color: #fff;
  border-radius: 3px;
  background: var(--red-shade, #eb3a56);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: normal;
}

.navbar2 form {
  border-radius: 3px;
  border: 0.2px solid #959595;
  background: #fff;
  margin-right: 28px;
}

.navbar2 form input {
  border: none;
}

.navbar2 ul li {
  color: var(--dark, #212121);
  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
}

.navbar2 ul li a {
  color: var(--dark, #212121);
  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
  margin-right: 7px;
}

.navbar2 .collapse .btngrop .login {
  background: transparent;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  height: 40px;
  padding: 11px 21px 12px 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.5px solid #000;
  margin-right: 28px;
}

.navbar2 .collapse .btngrop .signup {
  background: #f5aa30;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  height: 40px;
  padding: 11px 21px 12px 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .....................Banner...................... */
.banner {
  height: 100vh;
}

.banner-div {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner-div .global {
  position: absolute;
  left: 77%;
  transform: translate(0, -50%);
  top: 43%;
}

.banner-div .global img {
  height: 270px;
  width: 270px;
  border-radius: 80px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  -ms-border-radius: 80px;
  -o-border-radius: 80px;
}

.banner-button {
  margin-top: 5rem;
}

.banner video {
  height: 100vh;
  width: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: blur(2px) brightness(0.7);
  filter: blur(2px) brightness(0.7);
}

.banner .heading {
  position: relative;
}

.banner .heading h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 2px;
}

.banner .heading p {
  color: #fff;
  font-size: 28px;
  margin-top: 1rem;
  line-height: 35px;
}

.banner .viewcourse {
  border-radius: 3px;
  background-color: #28a9e0;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  width: 15%;
  padding: 8px 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  height: 50px;
  z-index: 999;
  white-space: nowrap;
}

.banner .viewcourse:hover {
  background-color: var(--logo-yellow, #f5aa30);
}

.banner .quiz {
  border-radius: 3px;
  background-color: #fff;
  width: 15%;
  padding: 8px 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  height: 50px;
  z-index: 999;
  white-space: nowrap;
}

.all-model .modal-dialog {
  max-width: unset;
}

.all-model .modal-content {
  width: 60vw;
  height: 90vh;
  margin: auto;
}

.all-model .all-course {
  padding: 1rem 3rem 1rem 3rem;
}

.all-model .all-course .all-ul {
  height: 78vh;
  overflow-y: scroll;
}

.all-model .all-course ul {
  display: flex;
  flex-wrap: wrap;
}

.all-model .all-course ul li {
  list-style: none;
  width: 32%;
  margin-top: 1rem;
}

.all-model .all-course ul li a {
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: 300;
  text-decoration: none;
  color: #000;
}

.all-model .all-course ul li img {
  height: 30px;
  width: 45px;
  object-fit: contain;
  padding-right: 10px;
}

.all-model .close {
  text-align: right;
  font-size: 25px;
  margin-right: 1rem;
  cursor: pointer;
}

.banner .quiz a {
  color: #414141;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.banner .quiz:hover {
  background-color: var(--logo-yellow, #f5aa30);

}

.banner .quiz:hover a {
  color: #fff;
}

.imgtext {
  border-radius: 6px;
  background: #28A9E0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.imgtext .banner-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.6rem 1.3rem;
  transform: 0.8s ease-in-out;
  -webkit-transform: 0.8s ease-in-out;
  -moz-transform: 0.8s ease-in-out;
  -ms-transform: 0.8s ease-in-out;
  -o-transform: 0.8s ease-in-out;
}

.imgtext .banner-service:hover {
  background-color: #F5AA30;
}

.imgtext a {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 0;
  padding-left: 15px;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  text-decoration: none;
}

.imgtext img {
  height: 32px;
}

.imgtext img:hover+p {
  text-decoration: underline;
}

.imgtext .rgtborder {
  border-right: 2px solid #3bb5e8;
}

/* ...........................Excelform .............................*/
.excel {
  background: -o-linear-gradient(197deg, #fff 42.44%, #a0cfff 167.6%);
  background: linear-gradient(253deg, #fff 42.44%, #a0cfff 167.6%);
}

.excel .rightxt h2 {
  color: #414141;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0;
  margin-top: 5rem;
}

.excel .rightxt p {
  color: #212121;
}

.excel .exclcard {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  height: 250px;
  overflow: hidden;
  position: relative;
  text-align: left;
}

.excel .exclcard:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.excel .exclcard h3 {
  border-radius: 3px;
  opacity: 0.8;
  background: -o-linear-gradient(bottom,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%),
    rgba(217, 217, 217, 0.07);
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(255, 255, 255, 0.2)),
      to(rgba(255, 255, 255, 0.2))),
    rgba(217, 217, 217, 0.07);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%),
    rgba(217, 217, 217, 0.07);
  -webkit-box-shadow: -15.1px 15.1px 15.1px 0px rgba(255, 255, 255, 0.07) inset,
    15.1px -15.1px 15.1px 0px rgba(165, 165, 165, 0.07) inset;
  box-shadow: -15.1px 15.1px 15.1px 0px rgba(255, 255, 255, 0.07) inset,
    15.1px -15.1px 15.1px 0px rgba(165, 165, 165, 0.07) inset;
  -webkit-backdrop-filter: blur(11.02299976348877px);
  backdrop-filter: blur(11.02299976348877px);
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
  position: absolute;
  width: 93%;
  padding-left: 10px;
  bottom: 39%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.excel .exclcard p {
  color: #414141;
  font-size: 0.9rem;
  padding: 0.5rem 1rem 0 1rem;
  font-weight: 500;
}

.excel .exclcard img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}

.excel .exclcard a,
.excel .exclcard button {
  color: #2769ae;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
  border: none;
  background: none;
  position: absolute;
  bottom: 4%;
  padding-left: 1rem;
  text-decoration: none;
}

.excel form {
  background: #f9f9f9;
  margin-top: -1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.excel form h3 {
  text-align: center;
}

.excel form .perinfo input,
.excel form .perinfo textarea,
.excel form .select select {
  border: 1px solid #e1e3eb;
}


.excel form .confirmbox button {
  background: #f5aa30;
}

.excel form .confirmbox button:hover {
  background: #df9a2b;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

/* ...........................OurSupport.......................... */
.oursupport {
  padding: 5rem 0;
  background: #FBFEFD;
}

.oursupport h2 {
  color: #101828;
  margin-bottom: 2rem;
}

.oursupport .card {
  padding: 30px 40px;
  border-radius: 12px;
  border: 2px solid #00a8e3;
  background: #fff;
  -webkit-box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
}

.oursupport .card h3 {
  color: #101828;
  padding-left: 20px;
}

.oursupport .card p {
  color: #646464;
  margin-top: 1.5rem;
}

.oursupport .card a {
  text-decoration: none !important;
}

.oursupport .card span {
  color: #1da7f5;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.36px;
  text-align: left;
  cursor: pointer;
}

.oursupport .card:hover span {
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
  color: #f5aa30;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
}

/* ............................Career track............................... */
.right-career {
  width: 68%;
  margin-top: 9rem;
}

.right-career button {
  border-radius: 8px;
  border: 1px solid #F5AA30;
  background: #F5AA30;
  color: #fff;
  padding: 10px 24px 10px 24px;
  white-space: nowrap;
}

.right-career button:hover {
  background: #df9a2b;
  color: #fff;
}

/* ............................certificatecourses......................... */
.certificationn {
  background: #fbfefd;
  padding: 5rem 0;
}

.certificationn h2 {
  color: #101828;
  text-transform: capitalize;
  margin-bottom: 2rem;
}

.certificationn .card {
  border-radius: 6px;
  background: var(--white, #fff);
  height: 160px;
  margin-bottom: 1rem;
  border: none;
  -webkit-box-shadow: 2px 2px 5px rgb(218, 217, 217);
  box-shadow: 2px 2px 5px rgb(218, 217, 217);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.certificationn .card h5 {
  color: var(--dark, #212121);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.09px;
}

.certificationn .card .card-body {
  height: 100%;
}

.certificationn .card .pop-title {
  display: none;
}

.certificationn .card .pop-title a {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: 600;
}

.certificationn img {
  width: 40%;
  margin: auto;
}


.certificationn .certibot {
  margin-bottom: 8.875rem;
}

.certificationn .card .right-link {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: -50%;
  left: 50%;
  bottom: -2.5rem;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  border: none;
}

.certificationn .card:hover .right-link {
  display: block;
  -webkit-transition: ease-in-out 0.8s;
  -o-transition: ease-in-out 0.8s;
  transition: ease-in-out 0.8s;
}

.certificationn .card:hover .pop-title {
  display: block;
  -webkit-transition: ease-in-out 0.8s;
  -o-transition: ease-in-out 0.8s;
  transition: ease-in-out 0.8s;
}

.certificationn .card:hover {
  height: 210px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.certificationn .slick-slider,
.certificationn .slick-slider .slick-list {
  height: 240px;
}

.slick-dots li.slick-active button:before {
  color: #1da7f5;
}

/* ...........................About........................... */
.about {
  padding: 5rem 0;
}

.about h2 {
  color: #101828;
}

.about p {
  color: var(--dark, #212121);
}

.about .left-about img {
  width: 100%;
}

.about .righttext {
  background-image: url(../image/aboutleft.png);
  padding-left: 30px;
  margin: 3rem 0;
}

.about .righttext .submit {
  border-radius: 8px;
  border: 1px solid #1da7f5;
  background: #1da7f5;
  -webkit-box-shadow: 0px 0px 0px 4px #ebfbff,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-shadow: 0px 0px 0px 4px #ebfbff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  padding: 12px 20px;
  white-space: nowrap;
}

.about .righttext .submit a {
  color: #fff;
  text-decoration: none;
}

.about .righttext .callsvg a {
  margin-bottom: 0;
  color: #0e70b8;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.about .aboutservice {
  gap: 18px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about .aboutservice p {
  margin-bottom: 0;
  padding-left: 10px;
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 600;
  -webkit-transition: ease-in-out 0.4s;
  -o-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
}

.about .righttext .callsvg img {
  width: 50px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: hithere 1s ease infinite;
  animation: hithere 1s ease infinite;
}

@-webkit-keyframes hithere {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes hithere {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.about .righttext .submit:hover {
  background: #0190e2;
}

.abouthover1:hover p,
.abouthover2:hover p,
.abouthover3:hover p,
.abouthover4:hover p {
  padding-left: 20px;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

.about .righttext .callsvg:hover img {
  width: 40px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

/* ..........................Getcertified....................... */
.certified {
  background: #fbfcfe;
  padding: 4rem 0;
}

.certified h2 {
  color: #101828;
}

.certified p {
  color: #667085;
  margin-bottom: 2rem;
}

.certified .card {
  height: 100%;
  border-radius: 8px;
  background: #fff;
  -webkit-box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.certified .card .card-title {
  color: var(--dark, #212121);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 10px;
}

.certified .card .card-text {
  color: #667085;
}

.certified .lastc {
  padding-bottom: 8.9375rem;
}

.certified .card .card-img-top {
  height: 200px;
}

.certified .uprimg {
  top: 25%;
  left: 50%;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background: hsla(0, 0%, 100%, 0.671);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
  align-items: center;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.certified .uprimg img {
  height: 40px;
}

.certified .card .rating-btn {
  position: relative;
  bottom: 0;
}

.certified .card .rating-btn p {
  color: #1da7f5;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.certified .card .rating-btn span {
  font-size: 1rem;
}

.certified .card .rating-btn a {
  color: #1da7f5;
  text-align: right;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.certified .all-pro {
  border-radius: 8px;
  background: #1da7f5;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 16px;
  border: none;
  width: 18%;
  margin: auto;
  text-decoration: none;
  white-space: nowrap;
}

.slick-prev:before,
.slick-next:before {
  color: #d6d6d6;
}

.certified .all-pro:hover {
  background: #0190e2;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

.slick-slide>div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

/* ...........................Learn#get ..............................*/
.learnget {
  background-image: url(../image/learn_get\ 1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5rem 0;
}

.learnget form {
  background-color: transparent;
  position: unset;
  border: none !important;
}

.learnget h3 {
  color: var(--white, #fff);
  font-size: 36px;
}

.learnget p {
  color: var(--light, #fafcfe);
  font-size: 25px;
  margin-bottom: 2.5rem;
}

.learnget p span {
  color: var(--logo-yellow, #f5aa30);
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

.learnget input {
  width: 40rem;
  padding: 8.2px 10px 11px 10px;
  border: none;
  border-right: none;
  border-radius: 4px 0 0 4px;
  color: rgba(235, 58, 86, 0.95);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: capitalize;
  outline: none;
}

.learnget input::-webkit-input-placeholder {
  color: red;
  opacity: 0.8;
  padding-left: 0.1rem;
}

.learnget input::-moz-placeholder {
  color: red;
  opacity: 0.8;
  padding-left: 0.1rem;
}

.learnget input:-ms-input-placeholder {
  color: red;
  opacity: 0.8;
  padding-left: 0.1rem;
}

.learnget input::-ms-input-placeholder {
  color: red;
  opacity: 0.8;
  padding-left: 0.1rem;
}

.learnget input::placeholder {
  color: red;
  opacity: 0.8;
  padding-left: 0.1rem;
}

.learnget .submit {
  color: #fff;
  border-left: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.learnget .learnlist {
  border-radius: 6px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2.125rem 0 2.0625rem 1rem;
}

.learnget .learnlist ul {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
}

.learnget .learnlist ul li {
  list-style-type: none;
  text-align: left;
  padding-top: 10px;
  text-decoration: none;
  width: 25%;
  padding-left: 16px;
}

.learnget .learnlist ul li:hover {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.learnget .learnlist ul li a {
  text-decoration: none;
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
}

.learnget .learnlist ul img {
  width: 9%;
  padding-right: 5px;
}

.learnget .learn_btn {
  background-color: #00a5dd;
  color: #fff;
  width: auto;
  padding: 8px 40px;
  border-radius: 8px;
  border: none;
  margin: auto;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}

.learnget .learn_btn:hover {
  background-color: #0290bf;
}

/* .............................withness ............................*/
.withness {
  padding: 5rem 0;
}

.withness h2 {
  margin-bottom: 2rem;
  color: var(--dark, #212121);
  text-align: center;
}

.withness .leftwith {
  border-radius: 6px 0px 0px 6px;
  background: #e4e4e4;
  width: 31%;
}

.withness .leftwith h4 {
  margin: 5.5rem 0 1.5rem 2.375rem;
}

.withness .leftwith .hidep {
  color: var(--dark, #212121);
  margin-left: 2.375rem;
}

.withness .leftwith .hidebtn {
  border-radius: 3px;
  background: #ff6179;
  color: var(--ss, #fff);
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  margin: 2rem 0 0 2.375rem;
  white-space: nowrap;
}

.withness .col1 {
  background-color: #53ffcb;
}

.withness .col2 {
  background-color: #ffb2b5;
}

.withness .col3 {
  background-color: var(--c-2, #00c3e1);
}

.withness .col4 {
  border-radius: 0px 6px 6px 0px;
  background-color: #ffe692;
}

.withness .rightwith h6 {
  margin: 2.75rem 0 3.625rem 0;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
}

.withness .rightwith .read {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  margin-bottom: 6rem;
}

.withness .rightwith,
.withness .leftwith {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.withness .rightwith .hov {
  color: var(--dark, #212121);
}

.withness .rightwith img {
  border-radius: 50%;
  position: absolute;
  bottom: 4rem;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.withness .rightwith,
.withness .leftwith {
  height: 60vh;
  position: relative;
}

.withness .rightwith .author {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  position: absolute;
  bottom: 1rem;
  text-align: center;
}

/* ............................contactform............................ */
.get-ans {
  background: #fbfefd;
  padding-bottom: 5rem;
}

.get-ans .contact-head {
  color: #000;
  margin-top: 6rem;
}

.get-ans .get-para {
  color: #212121;
}

.get-ans .accordion-item {
  border-radius: 6px;
  overflow: hidden;
  border: 0.5px solid #c2c2c2;
  margin-top: 1.5rem;
}

.get-ans .accordion-flush .accordion-item:first-child {
  border-top: 0.5px solid #c2c2c2;
}

.get-ans .accordion-flush .accordion-item:last-child {
  border-bottom: 0.5px solid #c2c2c2;
}

.get-ans .read-more {
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.3px;
}

.get-ans .read-more a {
  color: #0e70b8;
  text-decoration: none;
}

.get-ans form {
  background: #005797;
  width: 75%;
  margin-bottom: 2rem;
}

.get-ans form h6,
.get-ans form .formdiv label,
.get-ans form .confirmbox p {
  color: #000;
}

/* .......................learner sys.......................... */
.learner {
  background: -o-linear-gradient(top, #f6f8fa 0%, rgba(246, 248, 250, 0) 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#f6f8fa),
      to(rgba(246, 248, 250, 0)));
  background: linear-gradient(180deg, #f6f8fa 0%, rgba(246, 248, 250, 0) 100%);
  padding: 5rem 0;
}

.learner .appointment img {
  margin: -8rem 0 0 0;
}

.learner .appointment h4 {
  position: absolute;
  margin: -11rem 0 0 4rem;
  color: var(--w-1, #fff);
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.52px;
}

.learner .appointment h3 {
  position: absolute;
  margin: -6rem 0 0 4rem;
  color: #ffc059;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.learner .toptext {
  color: var(--dark, #212121);
  text-align: center;
}

.learner .toppera {
  color: var(--dark, #212121);
  text-align: center;
  padding-bottom: 2rem;
}

.learner .card {
  margin-bottom: 1.5rem;
  border: none;
  border-radius: 6px;
  border-bottom: 3px solid var(--bl2, #0775db);
  background: #fff;
  -webkit-box-shadow: 3px 3px 4px 0px rgba(74, 74, 74, 0.1);
  box-shadow: 3px 3px 4px 0px rgba(74, 74, 74, 0.1);
  height: 350px;
}

.learner .card .clintimg {
  margin-bottom: 1rem;
}

.learner .card .clintimg .img-top {
  border-radius: 50%;
  width: auto;
  margin: -4.9rem 0 0 0.5rem;
  height: 70px;
  width: 70px;
}

.learner .card .clintid h5 {
  font-size: 1rem;
  font-weight: 500;
  padding-top: 1.8rem;
  padding-left: 1rem;
}

.learner .card p {
  padding-left: 1rem;
  padding-right: 1.5rem;
}

.learner .card .star {
  width: 5rem;
  height: 1rem;
  margin-top: 2rem;
  margin-left: 6rem;
}

.learner .card .star1 {
  width: 5rem;
  margin-left: 10.5rem;
  margin-top: -3rem;
}

.learner .card .union {
  margin-top: -0.1rem;
}

.learner .card .clint-text {
  color: var(--dark, #212121);
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 140%;
  margin: 0 2rem;
}

.learner .card a {
  text-decoration: none !important;
  color: #00a5dd !important;
  margin-left: 1rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:after {
  content: "\276F";
  font-size: 55px;
  color: #dcdcdc;
  margin-left: 1.5rem;
}

.carousel-control-prev-icon:after {
  content: "\276E";
  font-size: 55px;
  color: #dcdcdc;
  margin-left: -3rem;
}

/* .................Partner.................... */
.partner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.partner h2 {
  color: #000;
  text-transform: capitalize;
  text-align: center;
  font-size: 26px;
  padding-bottom: 2rem;
}

.partner .slide {
  height: 100px;
  width: 200px;
}

.partner .slide img {
  height: 45px;
  width: 130px;
  -o-object-fit: contain;
  object-fit: contain;
}

.partner .slick-arrow {
  display: none !important;
}

/*........................... footer ..........................*/
footer {
  background: #0a2f4a;
  padding: 5rem 0;
}

footer .logo {
  width: 15%;
  height: 15%;
  margin-left: 1.5rem;
  padding-bottom: 2rem;
}

footer h4 {
  color: var(--White, #fff);
  font-size: 1rem;
  font-weight: 700;
  line-height: 40px;
}

footer .foo {
  margin-left: 20px;
}

footer .foo2 {
  margin-left: 30px;
}

footer .foo4 {
  margin-left: 30px;
}

footer .foo5 {
  margin-left: 35px;
}

footer .foo5 #dropdown-basic {
  background-color: #fff;
  color: rgba(140, 140, 161, 0.9);
  height: 2rem;
  width: 12.3rem;
  text-align: left;
  border: none;
}

footer .foo5 #dropdown-basic::after {
  margin-left: 3rem;
}

footer .foo5 .dropdown-item a {
  color: #000 !important;
  text-decoration: none !important;
}

footer .foo5 .dropdown-menu {
  height: 300px;
  width: 400px;
  overflow: scroll;
}

footer .search .fa {
  color: red;
}

footer .linklist li {
  list-style-type: none;
  color: #cfcfcf;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

footer .linklist a {
  color: #cfcfcf;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

footer .linklist a:hover {
  color: #F5AA30;
}

footer .linklist p {
  list-style-type: none;
  color: #ecf2c6;
  font-size: 12px;
}

footer .course select {
  color: rgba(140, 140, 161, 0.9);
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  height: 2rem;
  width: 12.3rem;
  border-radius: 6px;
}


footer hr {
  height: 2px;
  width: 95%;
  border-width: 0;
  color: gray;
  background-color: gray;
  margin-top: 1rem !important;
}

footer .stable {
  color: #b5b5b5;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: capitalize;
}

footer .search .form input {
  padding: 2px 9px;
  width: 10rem;
  word-spacing: 5px;
  border: 2px solid grey;
  border-right: none;
  border-radius: 8px 0 0 8px;
}

footer .submit {
  color: white;
  border: 2px solid grey;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

footer .social-link {
  display: flex;
  flex-wrap: wrap;
}

footer .social-link img {
  margin-right: 0.5rem;
}

footer .box h3 {
  color: var(--white, #fff);
  font-size: 1rem;
  font-weight: 700;
  line-height: 40px;
}

footer .box a {
  color: #c9c9c9;
  font-size: 14px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .box a:hover {
  color: #F5AA30;
}

/*------------------------------Testimonial----------------------------*/
.testimonial_banner {
  background-image: url("../image/bnd-newsletter-bg-copy.png");
  height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial_banner .testimonial-content {
  position: relative;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  /* text-align: center; */
}

.testimonial_banner .testimonial-content h1 {
  color: #fff;
}

/*------------------------------Testimonial Content----------------------*/
.testimonialcontent {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.testimonialcontent .learner-card {
  border-bottom: 1px solid #d9d9d9;
  height: auto;
  padding: 40px 30px;
}

.testimonialcontent .learner-card img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 200px;
  width: 200px;
}

.testimonialcontent .learner-card h5 {
  margin-top: 2rem;
  letter-spacing: 0.5px;
}

.testimonialcontent .learner-card .learner-text {
  background: #f1f1f1;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-left: 2rem;
  text-align: left;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 16px 20px -20px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.testimonialcontent .learner-card .learner-text:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 65px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid #f1f1f1;
  border-bottom: 13px solid transparent;
}

.testimonialcontent .learner-card .learner-text p {
  margin-bottom: 0;
}

/* --------------------------------------Product page---------------------------- */

/*---------------JAVASE8---------------*/
.javase {
  height: 100vh;
}

.javase .bg-image {
  height: 100%;
  width: 100%;
  position: absolute;
}

.javase .javalogo {
  margin-top: 4rem;
  width: 4%;
  -o-object-fit: contain;
  object-fit: contain;
}

.javase .java-banner {
  position: relative;
  top: 45%;
  -webkit-transform: translate(0, 45%);
  -ms-transform: translate(0, 45%);
  transform: translate(0, -50%);
}

.javase .java-banner h1 {
  color: #fff;
  margin-top: 1rem;
}

.javase .java-banner h2 {
  color: var(--logo-yellow, #f5aa30);
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  padding-left: 12px;
  margin-top: 1.5rem;
}

.javase .java-banner p {
  color: #fff;
  font-size: 18px;
  margin-top: 1.5rem;
  width: 60%;
}

.javase .java-banner span {
  color: var(--red-shade, #eb3a56);
  font-size: 0.9rem;
  font-weight: 800;
  line-height: normal;
  margin-top: 1.3rem;
}

.javase .upimage {
  position: absolute;
  top: 50%;
  right: 0%;
  border-radius: 5px;
  height: 280px;
  width: 280px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -o-object-fit: contain;
  object-fit: contain;
}

/*-------------------buttongroup---------------------*/
.btngroup {
  position: relative;
  bottom: 3rem;
  width: 100%;
  /* z-index: 10000; */
}

.btngroup .buttons {
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.2rem;
}

.btngroup .buttons img {
  width: 4.5rem;
  height: 3rem;
  padding: 0 13px;
}

.btngroup .buttons .div2 {
  border-left: 2px solid #ececec;
}

.btngroup .buttons p {
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 400;
  width: 17rem;
  padding-top: 1.3rem;
  padding-right: 0.5rem;
}

/*-----------------------rating------------------------*/
.ratting {
  background: #f8f8f8;
  padding-top: 7rem;
  padding-bottom: 2rem;
  /* z-index: 9999;
  position: relative; */
  margin-top: -7.8rem;
}

.ratting .rating-card {
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  padding: 16px;
}

.ratting .rating-card1 {
  border-radius: 3px;
  border-top: 3px solid var(--c2, #00c3e1);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.ratting .rating-card2 {
  border-radius: 3px;
  border-top: 3px solid var(--c3, #e4ba2d);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.ratting .rating-card3 {
  border-radius: 3px;
  border-top: 3px solid var(--red-shade, #eb3a56);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.ratting .rating-card img {
  width: 18%;
}

.ratting .rating-card .card-body {
  padding-left: 2rem;
}

.ratting .rating-card .card-body img {
  width: 120px;
  height: 25px;
  padding-left: 10px;
}

.ratting .rating-card .card-body h3 {
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
}

.ratting .rating-card .card-body p {
  color: var(--dark, #212121);
  font-size: 26px;
  margin-bottom: 0;
}

/*--------------------------javapopster----------------------------*/
.java-poster {
  background: #f8f8f8;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.java-poster .img-txt {
  border-radius: 6px;
  border: 1px solid #64adce;
  background: -o-linear-gradient(310deg, #eaf9ff 38.35%, #b9dae7 77.17%);
  background: linear-gradient(140deg, #eaf9ff 38.35%, #b9dae7 77.17%);
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.java-poster .img-txt img {
  width: 8%;
  height: 7%;
  padding-right: 1.5rem;
  padding-left: 1rem;
}

.java-poster .img-txt h2 {
  color: var(--dark, #212121);
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}

/*--------------------------product page Menu------------------------*/
.maincontent {
  background: #f8f8f8;
  padding-bottom: 4rem;
}

.javamenu {
  background: #fff;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(194, 194, 194, 0.15);
  box-shadow: 0px 2px 7px 0px rgba(194, 194, 194, 0.15);
  padding-left: 14px;
}

.javamenu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  border: none;
}

.javamenu ul li {
  border-bottom: 2px solid #fff;
}

.javamenu ul li button,
.javamenu ul li a {
  color: var(--dark, #212121);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  list-style: none;
  text-decoration: none;
  border: none !important;
  border-width: 0px !important;
  padding: 18px 9px;
}

.javamenu ul li:hover {
  border-bottom: 2px solid var(--tert, #007cb5);
}

.javamenu ul li:hover a,
.javamenu ul li:hover button {
  color: var(--tert, #007cb5) !important;
  border: none;
  border-width: 0px;
}

.javamenu ul li.activee {
  border-bottom: 2px solid var(--tert, #007cb5) !important;
}

.javamenu ul li.activee button {
  color: var(--tert, #007cb5) !important;
}

/*-------------------Menucontent------------------------*/

.menucontent p,
.menucontent ul li {
  color: var(--dark, #212121);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
}

.menucontent p img {
  width: 100%;
}

/*------------------Keyfeatures-----------------------*/
.keyfeature {
  border-radius: 6px;
  border-top: 2px solid var(--logo-blue, #0197d7);
  background: #fff;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(189, 189, 189, 0.15);
  box-shadow: 2px 2px 5px 0px rgba(189, 189, 189, 0.15);
  padding: 25px 25px 10px 25px;
}

.keyfeature h2,
.menucontent h4 {
  color: var(--head, #053347);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 12px;
}

.keyfeature li {
  padding-bottom: 18px;
}

.keyfeature p {
  margin-bottom: 0;
  padding-left: 10px;
  color: var(--dark, #212121);
  font-size: 1rem;
}

.menucontent .apllyform {
  border-radius: 6px;
  background: var(--Accent, #ebf0f4);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;
  overflow: hidden;
  margin-top: -7.2rem;
}

.menucontent .apllyform .apllyposter img {
  width: 100%;
}

.menucontent .apllyform .apllyposter .frstpara {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  color: var(--w1, #fff);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: capitalize;
  z-index: 999;
}

.menucontent .apllyform .apllyposter .scndpara {
  position: absolute;
  top: 4.5rem;
  left: 1rem;
  color: var(--logo-yellow, #f5aa30);
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  z-index: 999;
}

.menucontent .apllyform h6 {
  color: #000;
  font-weight: 600;
}

.menucontent .apllyform label {
  color: #000;
  font-weight: 500;
}

.menucontent table {
  border: 1px solid #bdbdbd;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.menucontent table thead tr {
  background-color: #00a5dd;
  color: #fff;
}

.menucontent table tbody tr:nth-child(even) {
  background-color: rgb(235, 235, 235);
}

.menucontent table tr th,
.menucontent table tr td {
  padding: 10px;
}

/*--------------------Admission Java----------------------*/
.jadmission {
  padding: 5rem 0;
}

.jadmission h2 {
  color: #053347;
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
}

.jadmission .admpara {
  color: var(--dark, #212121);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  margin-top: 1rem;
}

.jadmission .adm1,
.jadmission .adm2,
.jadmission .adm3 {
  height: 14.5rem;
  padding: 20px 16px;
}

.jadmission .adm1 {
  border-radius: 6px;
  border-top: 2px solid var(--logo-blue, #0197d7);
  background: #fff;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
  box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
}

.jadmission .adm2 {
  border-radius: 6px;
  border-top: 2px solid var(--logo-yellow, #f5aa30);
  background: #fff;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
  box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
}

.jadmission .adm3 {
  border-radius: 6px;
  border-top: 2px solid var(--red-shade, #eb3a56);
  background: #fff;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
  box-shadow: 4px 4px 5px 0px rgba(190, 190, 190, 0.15);
}

.jadmission img {
  width: 90px;
  padding-bottom: 10px;
}

.jadmission .adm-card h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
}

.jadmission .adm-card p {
  color: var(--dark, #212121);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
}

/*----------------------FAQ-------------------------*/
.faq {
  background: rgba(248, 248, 248, 0.8);
  padding-bottom: 6rem;
}

.faq .accordion {
  width: 100%;
}

.FAQ .accordion h2 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.54px;
}

.faq .faqhead {
  color: var(--head, #053347);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.19px;
  padding: 4rem 0 20px 0;
}

/* ...........................Career first page.............................. */
.career-first {
  background-image: url("../image/careeer-first-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 60vh;
}

.career-first .career-content {
  position: relative;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.career-first .career-content h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 50.4px;
  letter-spacing: 0.5px;
  text-align: center;
}

.career-first .career-content p {
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  text-align: center;
}

.first-content {
  background: linear-gradient(253deg, #fff 42.44%, #a0cfff 167.6%);
  padding-bottom: 80px;
}

.first-content h2 {
  text-transform: uppercase;
}

.first-content .first-head {
  margin-top: 5rem;
}

.first-content .first-poster {
  position: relative;
  margin: 50px 0;
}

.first-content .start-today {
  position: absolute;
  left: 25px;
  bottom: 10px;
  Padding: 10px 16px 10px 16px;
  background: #fff;
  color: #00a5dd;
  font-weight: 600;
  font-size: 15px;
  transform: 0.8s ease-in-out;
  -webkit-transform: 0.8s ease-in-out;
  -moz-transform: 0.8s ease-in-out;
  -ms-transform: 0.8s ease-in-out;
  -o-transform: 0.8s ease-in-out;
}

.first-content .start-today:hover {
  background: #00a5dd;
  color: #fff;
}

.first-content form {
  margin-top: -2.5rem;
}

/* ...........................Career Track Course............................ */

.track-course {
  background-image: url("../image/Craeer\ Course.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.trackcourse-content {
  position: relative;
  text-align: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.track-course h1 {
  color: #fff;
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.5px;
}

.course-content {
  background: linear-gradient(268.91deg, #FFFFFF 20.62%, rgba(160, 207, 255, 0.2) 87.25%);
  padding: 80px 0;
}

.course-content .card-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
}

.course-content .card-grid .card {
  height: 100%;
  border-radius: 8px;
  background: #fff;
  border: none;
  -webkit-box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.course-content .card-grid .card .card-title {
  color: var(--dark, #212121);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 10px;
}

.course-content .card-grid .card .card-text {
  color: #667085;
  height: 60px;
}

.course-content .card-grid .lastc {
  padding-bottom: 8.9375rem;
}

.course-content .card-grid .card .card-img-top {
  height: 250px;
  object-fit: cover;
}

.course-content .card-grid .card .rating-btn {
  position: relative;
  bottom: 0;
  justify-content: space-between;
}

.course-content .card-grid .card .rating-btn p {
  color: #1da7f5;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.course-content .card-grid .card .rating-btn span {
  font-size: 1rem;
}

.course-content .card-grid .card .rating-btn a {
  color: #1da7f5;
  text-align: right;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

/* ...........................Track detail pages............................. */
.detail-banner {
  background-image: url("../image/Track-detail-page.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.detail-banner .detail-content {
  position: relative;
  text-align: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.detail-banner .detail-content h1 {
  color: #ffff;
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.5px;
  text-align: center;
}


/* ............................Detail page............................. */
.detailcontent {
  padding: 80px 0;
  background: #F6F6F6;
}

.detailcontent img {
  height: 100%;
  width: 90%;
  object-fit: fill;
  border-radius: 50% 50% 0 0;
  -webkit-border-radius: 50% 50% 0 0;
  -moz-border-radius: 50% 50% 0 0;
  -ms-border-radius: 50% 50% 0 0;
  -o-border-radius: 50% 50% 0 0;
}

.detailcontent button {
  border-radius: 3px;
  background-color: #28a9e0;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 12px 24px;
  border: 1px solid #28a9e0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.detailcontent button:hover {
  background-color: #F5AA30;
  border: 1px solid #F5AA30;
}

/* ...........................Related page................................... */
.related {
  padding: 80px 0;
}

.related .row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 20px;
}

.related .card {
  border: none;
  box-shadow: 1px 1px 14.6px 0px #0000001F;
}

.related .card-title {
  padding: 30px 16px 0 12px;
  text-align: center;
}

.related h4 {
  font-size: 18px;
  padding-top: 20px;
  height: 90px;
}

.related .card-title img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.related a {
  text-align: center;
  padding-bottom: 25px;
}

.related button {
  color: #1da7f5;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.36px;
  cursor: pointer;
}

.related button:hover {
  color: #F5AA30;
}


/*......................................Track form modal..................................*/

.track-form .modal-dialog {
  max-width: 50%;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
}

.track-form .modal-content {
  width: 100%;
  left: 0 !important;
}

.track-form form .under-div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.track-form .checkbox {
  display: flex;
  align-items: center;
}

.track-form .formradio {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 50px
}

.track-form form label {
  padding: 0 10px 0 8px;
  ;
}

.track-form .info-div {
  display: grid !important;
}

.track-form .close {
  display: none;
}

.track-form .modal-content {
  /* left: 90%; */
  animation: bounce-in-right 1s ease;
  -webkit-animation: bounce-in-right 1s ease;
  background: transparent;
}

.trackform-modal .close {
  display: none;
}

.trackform-modal form {
  background-color: #f9f9f9;
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.track-form .perinfo {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.track-form .tracklabel {
  width: 30% !important;
}

.track-form textarea {
  width: 48% !important;
}

.track-form .note {
  width: 48%;
  padding-top: 8px;
}



/*............................Responsive Screen...............................*/
@media only screen and (max-width: 1440px) {
  .navbar .navbar-brand {
    margin-left: 0 !important;
  }

  .navbar .search-regi {
    margin-right: 0 !important;
  }


  .staffbanner .staffbnnr {
    top: 4.23rem !important;
  }

  .right-career {
    width: 100%;
    margin-top: 4rem;
  }

  .track-form form h6 {
    font-size: 0.8rem;
  }

  .track-form form label {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .track-form .formradio {
    padding-right: 20px;
  }

  .track-form .modal-dialog {
    max-width: 60% !important;
  }

}


@media only screen and (max-width: 1024px) {

  .track-form form .under-div {
    display: grid;
  }

  .track-form .formradio {
    padding-left: 0;
  }

  .navbar .navbar-nav {
    flex-wrap: wrap;
  }

  .tophead .top-call {
    padding: 0 !important;
  }

  .tophead .text-end {
    padding: 0 !important;
  }

  .banner .banner-service {
    padding: 0.5rem 1rem;
  }

  .banner-div .global img {
    height: 250px;
    width: 240px;
  }

  .registermodal .modal-content {
    left: 50% !important;
  }


  .staffbanner .staffbnnr {
    width: 47% !important;
    top: 6.6rem !important;
  }

  .staffcontent .card img {
    height: auto;
  }

  .networkcontent .card-loop {
    gap: 24px !important;
  }

  .networkcontent h2 {
    margin-top: 0 !important;
  }

  .all-model .modal-content {
    width: 90vw;
  }

  .catesec .row {
    grid-template-columns: auto auto auto !important;
  }

  .right-career {
    margin-top: 1rem;
  }

  .track-form .modal-dialog {
    max-width: 80% !important;
  }

  .searchresult {
    grid-template-columns: 31% 31% 31% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .navbar-nav .dropdown .all {
    width: 83.5rem;
    top: 2.7rem;
    -webkit-transform: translate(-16%, 0);
    -ms-transform: translate(-16%, 0);
    transform: translate(-16%, 0);
  }

  .vetrans-benifit .benifit-head {
    width: 92% !important;
  }


  .withness .rightwith,
  .withness .leftwith {
    height: 70vh;
  }

  .vetransbanner-content {
    top: 60% !important;
  }

  .certificationn .card {
    height: 200px;
  }

  .btngroup .buttons p {
    width: 10rem;
  }

  .btngroup {
    bottom: 2rem;
  }

  .javase .upimage {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  form label {
    padding: 0 9px 12px 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .certified .card .rating-btn a {
    padding-left: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1400px) {
  .banner-button button {
    width: 22% !important;
  }

  .banner-button {
    margin-top: 3rem;
  }

  .learner .card {
    height: 420px;
  }

  .clint-body span {
    display: none;
  }

  .partner .slide img {
    width: 100px;
  }

  .certified .card .rating-btn {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .certified .card .rating-btn a {
    margin-left: 0 !important;
  }

  .oursupport .card {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar .navbar-brand {
    margin-left: 0;
  }

  .navbar .search-regi {
    margin-right: 0;
  }

  .registermodal .modal-content {
    left: 0 !important;
  }

  .registermodal .close {
    display: block !important;
    position: relative;
    left: 93%;
    color: #fff;
    font-size: 20px;
  }

  .banner .heading {
    text-align: center;
  }

  .banner-div .global {
    display: none;
  }

  .banner .imgtext img {
    display: none;
  }

  .about-li {
    -webkit-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
  }

  .menubg {
    top: 3.6rem;
  }

  .navbar-collapse {
    text-align: center;
  }

  .navbar-nav .dropdown .all-co {
    display: none;
  }

  .search-regi {
    justify-content: space-between;
    width: 100%;
  }

  .search-modal .modal-content .search-box {
    width: 90vw;
  }

  .search-modal .modal-content {
    width: 95vw;
  }

  .service-drop .left-side {
    width: 15rem;
    border-radius: 4px 4px 0 0;
  }

  .service-drop .right-side {
    border-radius: 0 0 4px 4px;
  }

  .service-drop {
    width: 13rem;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }

  .text-end {
    text-align: center !important;
  }

  .banner .viewcourse,
  .banner .quiz {
    width: 28%;
  }

  .banner .banner-service {
    padding: 0.5rem 1rem;
  }

  .banner .imgtext p {
    padding-left: 0;
  }

  .banner-button {
    justify-content: center;
  }

  .excel .rightxt {
    text-align: center;
    margin-bottom: 1rem;
    order: 2;
  }

  .excel .exclcard h3 {
    bottom: 27%;
  }

  .excel .exclcard {
    height: 340px;
  }

  .excel form {
    bottom: 0;
    top: 1rem;
    margin-top: 1rem;
  }

  .excel form .perinfo input,
  .excel form .perinfo textarea,
  .excel form .select select {
    padding: 8px 10px;
  }

  .poster h4 {
    margin-left: 2rem;
    margin-top: -8rem;
  }

  .poster h3 {
    margin-left: 2rem;
    margin-top: -4rem;
  }

  .appointment img {
    width: 100%;
  }

  .certified h2,
  .certified h5,
  .certified p {
    text-align: center;
  }

  .certified .card .card-img-top {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .certificationn img {
    width: 20%;
  }

  .about img {
    height: 100%;
    width: 100%;
  }

  .get-ans .con-col {
    padding-bottom: 3rem;
  }

  .learnget .learnlist ul img {
    width: 14%;
  }

  .learnget .learnlist ul li a {
    font-size: 12px;
  }

  .learnget form {
    position: unset;
  }

  .partner .slide img {
    padding-right: 60px;
  }

  .about .aboutservice img {
    width: 50px;
  }

  .contactform .con-col {
    padding-bottom: 3rem;
  }

  .learner {
    margin-top: 3rem;
  }

  .learner .card p {
    font-size: 0.9rem;
  }

  .learner .card {
    height: 450px;
  }

  .learner .card .clint-body span {
    display: none;
  }

  .withness .rightwith,
  .withness .leftwith {
    height: 65vh;
  }

  .get-ans form {
    width: 100%;
  }

  .about .righttext {
    text-align: center;
  }

  .callsvg {
    width: 30%;
  }

  .certified .all-pro {
    width: 25%;
  }

  .aboutcard .card {
    height: 490px;
  }

  .learner .card .star {
    display: none;
  }

  footer .logo {
    width: 60%;
  }

  footer .foo,
  footer .foo2,
  footer .foo3,
  footer .foo4,
  footer .foo5 {
    margin: 0;
  }

  footer .foo4,
  footer .foo5 {
    margin-top: 1rem;
  }

  .javase .upimage {
    display: none;
  }

  .btngroup .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0;
  }

  .btngroup {
    bottom: 0rem;
  }

  .javase .java-banner p {
    width: 100%;
  }

  .javase .java-banner h2 br {
    display: none;
  }

  .rating {
    padding-top: 8rem;
  }

  .javamenu ul li button {
    padding: 5px 9px;
  }

  .java-poster .img-txt img {
    display: none;
  }

  .jadmission h2,
  .jadmission .admpara,
  .menucontent h3,
  .menucontent p {
    text-align: center;
  }

  .jadmission .button1 {
    margin: auto;
  }


  .menucontent .apllyform {
    margin-left: 0;
    margin-top: 2.5rem;
  }

  .menucontent .apllyform .apllyposter .frstpara {
    font-size: 0.9rem;
    top: 1.5rem;
  }

  .menucontent .apllyform .apllyposter .scndpara {
    top: 3.5rem;
    font-size: 1rem;
  }

  .faq .accordion {
    width: 100%;
  }

  .navbar2 .btngrop {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navbar2 ul {
    gap: 10px;
  }

  .ratting {
    margin-top: 3.2rem;
    padding-top: 0;
  }

  .first-content form {
    margin-top: 2rem;
  }

  .course-content .card-grid {
    grid-template-columns: auto auto !important;
  }

  /*.......................Admission page............................*/
  .admissionbanner {
    background-position: center;
  }

  .admissionform form {
    width: 100% !important;
  }

  /*.........................class schedule..........................*/
  .classbanner {
    background-position: center !important;
  }

  .left-table form {
    position: unset;
    margin-top: 4rem;
  }

  .left-table {
    display: -ms-grid;
    display: grid;
  }

  .course-select {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /*......................Vetrans...................................*/
  .vetrans-benifit .benifit-head {
    width: 91% !important;
  }

  .vetrans-benifit .benifit-head h2 {
    font-size: 30px !important;
  }

  /*............................Free workshop.........................*/
  .workshop {
    background-position: center;
  }

  .MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
    width: 27% !important;
    margin-left: -6rem;
  }

  /*.......................... contact us .........................*/
  .conatct-banner {
    background-position: unset !important;
  }

  /*............................overview/ corporate/ startegy............................*/

  .order {
    order: 2;
  }

  .corporatecontent .corporate-div {
    width: 48% !important;
  }

  .startegycontent .corporate-div {
    width: 50% !important;
  }


  .schoolership-content .schooler-head {
    margin-top: 2rem;
  }


  /*...........................schoolership............................*/


  /*.............................job placement..........................*/
  .job-content .right-tabs ul li {
    width: 47% !important;
  }

  .job-content .right-tabs ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: unset !important;
    -webkit-box-direction: unset !important;
    -ms-flex-direction: unset !important;
    flex-direction: unset !important;
    gap: 10px;
  }

  .job-content .tab-content .pane-content {
    flex-direction: column;
  }

  /*...................................Training................................*/
  .training-row h2 {
    margin-top: 2rem;
  }


  .trainingcontent ul button {
    width: 21rem !important;
  }

  .trainingcontent ul {
    gap: 20px;
  }

  /*.................................Financial..........................*/
  .finanicial-accor .financial-para {
    flex-direction: column;
  }

  /*.............................web service........................*/
  .webservice .row {
    gap: 20px;
  }

  .webportfolio .row {
    gap: 20px;
  }

  .webservice .card {
    width: 21rem !important;
  }

  /*.......................staffcontent...........................*/
  .staffcontent .row {
    gap: 25px;
  }

  .staffcontent .card {
    height: auto !important;
  }

  .delivery-contact form {
    width: 100% !important;
  }

  .networkcontent .card {
    width: 48% !important;
  }

  /*....................delivery method...................*/
  .deliverycontent ul button {
    width: 19rem;
  }

  .deliverycontent ul {
    gap: 10px;
  }

  .staffbanner .staffbnnr {
    display: none;
  }

  .staffaccorimg h2 {
    margin-top: 2rem;
  }

  .network-content {
    top: 65% !important;
  }

  .networkcontent h2 {
    margin-top: 2rem !important;
  }

  .catesec .row {
    grid-template-columns: auto auto !important;
  }

  .track-form .perinfo {
    gap: 10px;
  }

  form .perinfo input {
    margin-top: 0;
  }

  .searchresult {
    grid-template-columns: 48% 48% !important;
  }
}



@media only screen and (max-width: 430px) {
  h2 {
    font-size: 25px !important;
    line-height: 35px !important;
  }

  form .confirm {
    margin-top: 0.5rem;
  }

  .slick-dots {
    width: 94%;
  }

  .navbar-nav .dropdown .all-co {
    display: none;
  }

  .tophead {
    display: none;
  }

  .header {
    display: none;
  }

  .menubg {
    top: 0;
  }

  .banner .heading h1 {
    font-size: 40px;
  }

  .banner .imgtext {
    display: none !important;
  }

  .banner .quiz {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  .banner .viewcourse,
  .banner .quiz {
    width: 90%;
  }

  .banner .viewcourse {
    margin-bottom: 1rem;
  }

  .banner .row {
    margin-left: 0;
    margin-right: 0;
  }

  .banner .banner-service {
    display: -ms-grid;
    display: grid;
    width: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .banner .rightborder2 {
    border-right: none !important;
  }

  .banner .imgtext p {
    padding-left: 0;
  }

  .banner .imgtext img {
    margin: auto;
  }

  .excel .rightxt h2 {
    margin-top: 2rem;
  }

  .excel .exclcard {
    height: 260px;
  }

  .excel .exclcard h3 {
    font-size: 1rem;
    bottom: 50%;
  }

  form label {
    padding: 0 7px 12px 6px;
  }

  form .confirm label {
    font-size: 11px;
  }

  .certificationn .card .pop-title {
    display: block;
  }

  .excel form .confirmbox button {
    margin-top: 1rem;
  }

  .about .row {
    margin-left: 0;
    margin-right: 0;
  }

  .certified .card .rating-btn a {
    margin-left: 0 !important;
  }

  .oursupport h5,
  .oursupport h2,
  .certificationn h5,
  .certificationn h2,
  .contactform .contact-head,
  .contactform p {
    text-align: center;
  }

  .certificationn h5 {
    padding-top: 0;
  }

  .certificationn {
    padding-bottom: 0;
  }

  .poster h4 {
    font-size: 12px;
    margin-left: 1rem;
    margin-top: -4rem;
  }

  .poster h3 {
    font-size: 18px;
    margin-left: 1rem;
    margin-top: -2rem;
  }

  .poster {
    top: 2.5rem;
  }

  .aboutservice img {
    display: none;
  }

  .aboutservice .abouthover1,
  .aboutservice .abouthover2,
  .aboutservice .abouthover3,
  .aboutservice .abouthover4 {
    width: 100% !important;
    display: unset !important;
  }

  .aboutservice .abouthover4 {
    margin-bottom: 2rem;
  }

  .know-btn {
    display: unset !important;
  }

  .about .righttext .callsvg img {
    display: none;
  }

  .about .know-btn a {
    margin: auto;
  }

  .about .btn-no {
    display: -ms-grid;
    display: grid;
  }

  .callsvg {
    display: none !important;
  }

  .about .righttext .submit {
    width: 100%;
  }

  .about .righttext {
    padding: 0;
  }

  .withness {
    display: none;
  }

  .learnget h3 {
    font-size: 30px;
    margin-top: 3.125rem;
  }

  .learnget input {
    width: 85%;
  }

  .learnget .submit {
    width: 15%;
  }

  .learnget .learnlist {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .learnget form {
    position: static;
  }

  .learnget .learnlist ul img {
    display: none;
  }

  .learnget .learnlist ul li a {
    font-size: 0.9rem;
    text-decoration: none;
  }

  .learnget .learnlist ul li {
    width: 50%;
    padding-left: 6px;
  }

  .get-ans form {
    width: 100%;
  }

  .get-ans form label {
    padding: 0 6px 12px 8px;
  }

  .get-ans form .confirm label {
    padding-bottom: 0;
  }

  .get-ans form .confirmbox button {
    margin-top: 1rem;
  }

  .partner {
    height: 100%;
    margin-bottom: 0;
  }

  .partner .slide img {
    padding-right: 114px;
    width: 150px;
    height: 33px;
  }

  .get-ans .get-para,
  .get-ans .contact-head {
    text-align: center;
  }

  .aboutcard .card {
    height: 360px;
  }

  .contactform form label {
    padding: 0 7px 0 8px;
  }

  .learner .card {
    height: 560px;
  }

  footer .foo,
  footer .foo2,
  footer .foo3,
  footer .foo4,
  footer .foo5 {
    text-align: center;
  }

  footer .search .form {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer .row {
    margin-left: 0;
    margin-right: 0;
  }

  .foo3 .linklist {
    margin-top: 4rem !important;
  }

  footer .social-link {
    justify-content: center;
  }


  .partner .slide {
    height: 65px;
    width: 170px;
  }

  .contactform form {
    width: 100%;
    margin-top: 4rem;
  }

  .contactform form button {
    margin-top: 1rem;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .certified .all-pro {
    width: 55%;
  }

  .certified h5,
  .certified p {
    text-align: center;
  }

  .about .aboutservice {
    display: -ms-grid !important;
    display: grid !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .catesec .row {
    grid-template-columns: auto !important;
  }

  .first-content .start-today {
    left: 13px;
    bottom: 5px;
    Padding: 5px 10px 3px 10px;
    font-size: 7px;
  }

  .first-content .first-poster {
    margin: 20px 0;
  }

  .course-content .card-grid {
    grid-template-columns: auto !important;
  }

  /*........................admission page..........................*/
  .admission-content {
    text-align: center;
  }

  .admission-content p {
    width: 100% !important;
  }

  /*........................class schedule....................*/
  .classbanner .class-content {
    text-align: center;
  }

  .left-table form {
    margin-top: 2rem;
  }

  .course-select {
    display: none;
  }

  .class-table select {
    width: 100% !important;
  }

  /*........................vetrans............................*/
  .vetransbanner-content,
  .vetrans-banner h1,
  .vetrans-banner p,
  .benifitdetail h2,
  .benifitdetail p {
    text-align: center !important;
  }

  .vetrans-benifit .benifit-head {
    width: 93% !important;
  }

  /*.........................Free Workshop.....................*/
  .workshop .workshop-content {
    text-align: center;
  }

  .tss-1qtl85h-MUIDataTableBodyCell-root.tss-1ej321f-MUIDataTableBodyCell-cellHide.tss-1t2q2nr-MUIDataTableBodyCell-stackedHeader.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
    display: none;
  }

  .MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
    width: 70% !important;
    margin-left: -6rem;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4.css-zcmli0-MuiGrid-root {
    display: -ms-grid;
    display: grid;
  }

  /*......................................Contact us ...........................*/
  .conatct-banner h1,
  .conatct-banner {
    text-align: center !important;
  }

  .contact-info .contact-detail {
    margin-top: 3rem;
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  .contact-info img {
    padding-top: 2rem;
  }

  /*...............................overview...........................*/
  .corporatecontent .corporate-div {
    width: 100% !important;
  }

  /*................................corporate-content.....................*/
  .startegycontent .corporate-div {
    width: 100% !important;
  }

  /*..............................strategy-content.....................*/
  .startegycontent .corporate-div {
    width: 100% !important;
  }

  /*..................................partner-content.........................*/
  .partner-content br {
    display: none;
  }

  .partner-content {
    text-align: center;
  }

  .partnercontent img {
    margin-top: 1rem;
  }

  /*.............................career-content...........................*/
  .careercontent {
    margin-bottom: 4rem;
  }

  .careercontent h2 {
    margin-top: 1rem;

  }

  .careercontent {
    padding: 0 !important;
  }

  /*.................................rent-content..............................*/
  .rent-content {
    text-align: center;
  }

  .rent-content br {
    display: none;
  }

  /*...........................schoolership............................*/


  .job-content .right-tabs ul li {
    width: 47% !important;
  }

  .job-content .right-tabs ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: unset !important;
    -webkit-box-direction: unset !important;
    -ms-flex-direction: unset !important;
    flex-direction: unset !important;
    gap: 10px;
  }

  .schooler-contact form input[type="text"],
  .schooler-contact form input[type="email"],
  .schooler-contact form input[type="number"] {
    width: 100%;
  }

  .it-category {
    width: 100% !important;
  }

  /*................................free palning...............................*/

  .rightfree-content form .rightfree-name {
    display: -ms-grid !important;
    display: grid !important;
  }

  .free-content h2 {
    margin-top: 2rem;
  }

  /*.............................job placement..........................*/
  .job-content .right-tabs ul li {
    width: 100% !important;
  }

  .job-content .right-tabs ul {
    display: -ms-grid;
    display: grid;
  }

  .delivery-contact form {
    width: 100% !important;
  }

  .job-content .top-tab ul li {
    width: 50% !important;
    margin-bottom: 0.07rem !important;
  }

  .job-content .top-tab ul li button i {
    display: none;
  }

  .job-content .top-tab ul li button {
    padding: 12px 0 !important;
    font-size: 12px !important;
  }

  .job-content .top-tab ul li:first-child {
    border-top-left-radius: 0 !important;
  }

  .job-content .top-tab ul li:last-child {
    border-top-right-radius: 0 !important;
  }

  .job-content .tab-content .tab-pane img {
    height: unset !important;
    width: 100% !important;
  }


  /*..............................web desin....................*/
  .web-contact .name {
    flex-wrap: wrap;
  }

  .web-contact form {
    padding: 30px 10px !important;
  }

  .web-contact .web-message,
  .web-contact .web-button {
    width: 100% !important;
  }

  .web-contact .web-button {
    height: 48px !important;
  }


  /*................................Training ..................*/
  .trainingcontent img {
    width: 100% !important;
  }

  /*....................Student login....................*/

  .studentcontent .content-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .studentcontent .content-div .content-column {
    width: 100% !important;
  }

  .studentcontent .content-div .content-btngroup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
  }

  /*.....................Network..................*/
  .networkcontent .card {
    width: 100% !important;
  }

  /*...............................category...........................*/
  .related-card {
    width: 50% !important;
  }

  .catesec .card {
    height: 440px;
  }

  .all-model .all-course {
    padding: 1rem 1rem 3rem 1rem;
  }

  .all-model .all-course ul {
    column-gap: 15px;
    padding-left: 0;
  }

  .all-model .all-course ul li {
    width: 47%;
  }

  .all-model .all-course ul li img {
    display: none;
  }

  .track-form .tracklabel {
    width: 48% !important;
  }

  .track-form .checkbox {
    display: block;
  }

  .track-form textarea {
    width: 88%;
  }

  .track-form .note {
    width: 100%;
  }

  .track-form .modal-dialog {
    margin: 0 1rem;
  }

  .searchresult {
    grid-template-columns: 100% !important;
  }
}

/*.........................media query end...........................*/

/*----------------------Ctaegory Page---------------------*/
.catebanner {
  background-image: url("../image/microsoft-bg\ 1.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  width: 100%;
}

.catebanner .banner-text {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.catebanner .cate-img {
  height: 50px;
  width: 50px;
  -o-object-fit: contain;
  object-fit: contain;
}

.catebanner h1 {
  color: var(--w1, #fff);
}

.catebanner .cate-para {
  color: var(--w1, #fff);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 2rem;
}

.cateRat .rating {
  padding-top: 3rem !important;
}

/*---------------------buttongroup---------------------*/
.cateservice {
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
}

.cateservice .buttons {
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.2rem;
}

.cateservice .buttons img {
  width: 4.5rem;
  height: 3rem;
  padding: 0 13px;
}

.cateservice .buttons .div2 {
  border-left: 2px solid #ececec;
}

.cateservice .buttons p {
  color: var(--dark, #212121);
  font-size: 1rem;
  width: 17rem;
  padding-top: 1.3rem;
}

/*--------------------Cate-rating-------------------*/
.cate-ratting {
  background: #f8f8f8;
  padding-top: 6rem;
  padding-bottom: 2rem;
}

.cate-ratting .rating-card {
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  padding: 16px;
}

.cate-ratting .rating-card1 {
  border-radius: 3px;
  border-top: 3px solid var(--c2, #00c3e1);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.cate-ratting .rating-card2 {
  border-radius: 3px;
  border-top: 3px solid var(--c3, #e4ba2d);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.cate-ratting .rating-card3 {
  border-radius: 3px;
  border-top: 3px solid var(--red-shade, #eb3a56);
  background: #fff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
  box-shadow: 0px 3px 18px 0px rgba(223, 223, 223, 0.25);
}

.cate-ratting .rating-card img {
  width: 18%;
}

.cate-ratting .rating-card .card-body {
  padding-left: 2rem;
}

.cate-ratting .rating-card .card-body img {
  width: 120px;
  height: 25px;
  padding-left: 10px;
}

.cate-ratting .rating-card .card-body h3 {
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.cate-ratting .rating-card .card-body p {
  color: var(--dark, #212121);
  font-size: 26px;
  margin-bottom: 0;
}

/*---------------------category Content-----------------------*/
.catecontent {
  background: #f8f8f8;
}

.cate-poster {
  position: absolute;
  bottom: 2rem;
  background: transparent;
  padding: 0;
  width: 100%;
}

.catecontent p {
  color: var(--dark, #212121);
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
}

.catecontent span {
  color: var(--head, #053347);
  font-size: 1rem;
  font-weight: 700;
  line-height: 155%;
  letter-spacing: 0.5px;
}

.catecontent .oraclecate {
  width: 600px;
  height: 150px;
  margin: 2rem 0;
}

.catecontent h2 {
  color: #053347;
  text-shadow: 0.3px 0.3px 0.5px rgba(0, 0, 0, 0.15);
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
}

.catecontent ul {
  margin-bottom: 2rem;
}

.catecontent ul li {
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  list-style: none;
  margin-top: 1.5rem;
}

.catecontent ul li img {
  height: 30px;
  width: 30px;
  padding-right: 10px;
}

/*--------------------Category Section----------------------- */
.catesec {
  background: -o-linear-gradient(bottom,
      rgba(124, 177, 226, 0.1) 0%,
      rgba(255, 255, 255, 0.23) 168%);
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(124, 177, 226, 0.1)),
      color-stop(168%, rgba(255, 255, 255, 0.23)));
  background: linear-gradient(0deg,
      rgba(124, 177, 226, 0.1) 0%,
      rgba(255, 255, 255, 0.23) 168%);
  padding: 3rem 0;
}

.catesec h3 {
  padding-top: 4rem;
}

.catesec .row {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 30px;
}

.catesec .card {
  border: none;
  height: 460px;
}

.catesec .course-img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 300px;
  width: 100%;
}

.catesec .card .card-title {
  color: var(--dark, #212121);
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  height: 100px;
}

.catesec .card a {
  color: #eb3a55;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
  border: 1px solid #eb3a55;
}

/*......................Admission Page.............................*/
.admissionbanner {
  height: 60vh;
  background-image: url("../image//admissionbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.admission-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.admissionbanner h1 {
  color: #fff;
}

.admission-content p {
  color: #fff;
  width: 50%;
}

/*.....................Admission form.................*/
.admissionform {
  padding: 5rem 0;
}

.admissionform h2 {
  text-align: center;
  font-size: 30px;
}

.admissionform .form-para {
  color: #053347;
  text-align: center;
  margin-bottom: 2rem;
}

.admissionform form {
  background: #ebf0f4;
  width: 50%;
}

.admissionform form h3 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.admissionform form h6 {
  color: #000;
}

.admissionform form .confirmbox button {
  background: #0197d7;
  border: none;
  width: 100%;
}

.admissionform form .confirmbox button:hover {
  background: #0386bf;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

/*.............................Vetrans.........................*/
.vetrans-banner {
  background-image: url("../image/vetransbanner.jpg");
  background-size: cover;
  height: 80vh;
}

.vetransbanner-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.vetrans-banner img {
  width: 100px;
}

.vetrans-banner h1 {
  color: #fff;
  text-align: left;
}

.vetrans-banner .vetransbanner-content p {
  color: #fff;
  text-align: left;
}

/*.......................Vetrans Benifit.........................*/
.vetrans-benifit {
  background: #f8f8f8;
  padding-bottom: 5rem;
}

.vetrans-benifit .benifit-head {
  background-color: #fff;
  padding: 20px 0;
  margin-bottom: 2rem;
  margin-top: -2rem;
  position: absolute;
  width: 68%;
  z-index: 1;
  border-radius: 5px;
}

.vetrans-benifit .benifit-head h2 {
  text-align: center;
}

.vetrans-benifit .accordion h2 button {
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: #00a5dd;
  border-radius: 8px !important;
}

.vetrans-benifit .accordion .accordion-body {
  font-size: 1rem;
  text-align: left;
}

.vetrans-benifit .accordion .accordion-item {
  margin-bottom: 1rem;
  border-radius: 8px !important;
}

.vetrans-benifit form {
  background-color: #ebf0f4;
}

/*.......................Benifit Detail.............................*/
.benifitdetail {
  padding: 5rem 0;
}

.benifitdetail h2 {
  text-align: left;
}

.benifitdetail p {
  text-align: left;
}

.benifitdetail form {
  background-color: #ebf0f4;
}

.benifitdetail .benifitfaq {
  margin-top: 1rem;
}

.benifitdetail .accordion h2 button {
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: #00a5dd;
  border-radius: 8px !important;
}

.benifitdetail .accordion .accordion-body {
  font-size: 1rem;
  text-align: left;
}

.benifitdetail .accordion .accordion-item {
  margin-bottom: 1rem;
  border-radius: 8px !important;
  border: 1px solid #ededed;
}

/*...........................Contact Us........................*/
.conatct-banner {
  background-image: url("../image/Contactusbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 60vh;
}

.conatct-banner .contactbanner-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.conatct-banner h1 {
  color: #fff;
  text-align: left;
}

/*....................Contact info.......................*/
.contact-info {
  padding: 5rem 0;
}

.contact-info form {
  background-color: #ebf0f4;
  display: -ms-grid;
  display: grid;
  border-radius: 8px;
}

.contact-info form h2 {
  color: #2b2b2b;
  text-align: center;
}

.contact-info form label {
  margin-top: 1rem;
}

.contact-info form input[type="text"],
.contact-info form input[type="email"],
.contact-info form input[type="number"],
.contact-info form textarea {
  padding: 8px 0 8px 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
}

.contact-info .contact-detail {
  background-color: #019ed4;
  border-radius: 8px;
  height: 100%;
}

.contact-info h2 {
  color: #fff;
}

.contact-info p {
  color: #fff;
  text-align: left;
}

.contact-info .contact-detail .number {
  color: #fff;
  text-align: left;
}


.contact-info .contact-detail .number a {
  color: #fff;
  text-decoration: none;
}


.contact-info .contact-detail .mail {
  color: #fff;
  text-align: left;
}


.contact-info .contact-detail .mail a {
  color: #fff;
  text-decoration: none;
}

.contact-info .contact-detail .contact-call p {
  margin-bottom: 0;
}

.contact-info .contact-detail .social-icon a {
  color: #fff;
  text-decoration: none;
  padding-left: 1rem;
}

/*........................Schoolership.........................*/
.schooler-banner {
  background-image: url("../image/achoolership.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  background-position: top;
}

.schooler-banner .schooler-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.schooler-banner h1 {
  color: #fff;
}

/*................Schoolership content...................*/
.schoolership-content {
  padding: 5rem 0;
}

.schoolership-content .schooler-head {
  text-align: left;
}

.schoolership-content p {
  text-align: left;
}

.schoolership-content .schooler-contact h2 {
  color: #00a5dd;
  text-align: center;
}

.schoolership-content .best-query {
  background-color: #fbf6e4;
  padding: 30px;
  margin-top: 2.5rem;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

.schoolership-content .schooler-contact form {
  background-color: #ebf0f4;
}

.schoolership-content .schooler-contact form h3 {
  text-align: center;
}

.schooler-contact form .name {
  gap: 10px;
}

.schoolership-content .schooler-contact form .under-div h3 {
  color: #000;
  text-align: left;
  padding: 12px 0 8px 0;
}

.schooler-contact form input[type="text"],
.schooler-contact form input[type="email"],
.schooler-contact form input[type="number"] {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 10px 0 8px 10px;
  width: 100%;
  margin-top: 10px;
}

.schooler-contact form textarea {
  margin-top: 1rem;
  padding-left: 10px;
  padding-top: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-top: 10px;
}

.schooler-contact form .upload-essay {
  background-color: transparent;
  color: #f5aa30;
  padding: 10px 12px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  border: 1px solid #f5aa30;
  margin-top: 1rem;
  cursor: pointer;
}

.schooler-contact form .check p {
  margin-bottom: 0;
  padding-left: 10px;
}

.schooler-contact .err {
  color: red;
}

/*............................Class Schedules..........................*/
.classbanner {
  background-image: url("../image/class-Schedule.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  width: 100%;
}

.classbanner .class-content {
  position: relative;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.classbanner .class-content h1 {
  color: #fff;
}

/*...........................Class Tabel....................*/
.class-table h2 {
  font-size: 26px;
  text-align: left;
  font-weight: 600;
}

.class-table select {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 10px 0 10px 10px;
  width: 35%;
  outline: none;
  float: right;
  margin-bottom: 1.5rem;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.class-table select option {
  background-color: #ffff;
  color: #007cb5;
}

.class-table form {
  background-color: #f1f0f0;
}

.class-table table {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.class-table table h4 {
  font-size: 18px;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
}

.class-table table .tabel-name {
  background-color: #00a5dd;
  padding: 12px;
  border: none;
  border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  -o-border-radius: 8px 8px 0 0;
}

.class-table table thead .tr-head {
  border: none;
}

.class-table table thead .tr-text {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/*................................Free Career Planning....................*/
.free-palnning {
  background-image: url("../image/free_cureer_planing.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.free-palnning .planning-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
}

.free-palnning .planning-content h1 {
  color: #fff;
  margin-bottom: 1rem;
}

.free-palnning .planning-content p {
  color: #fff;
}

/*....................................Free Planning Content........................*/
.free-content {
  padding: 5rem 0;
}

.free-content .delivery-contact {
  padding: 0;
}

.free-content h2 {
  margin-bottom: 1rem;
}

.session-call {
  font-size: 27px;
  line-height: 38px;
}

.rightfree-content form {
  width: 100% !important;
}

.rightfree-content p {
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.rightfree-content form .rightfree-name {
  gap: 10px;
}

.rightfree-content form label {
  color: #000;
  margin-top: 1rem;
}

.rightfree-content form input[type="text"],
.rightfree-content form input[type="email"],
.rightfree-content form input[type="number"] {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px 0 10px 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 1rem;
}

.rightfree-content form button {
  padding: 10px 35px;
  background-color: #f5aa30;
  color: #fff;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.rightfree-content form button:hover {
  background-color: #f19c13;
  color: #fff;
}

/*..........................Free WorkShop..............................*/
.workshop {
  background-image: url("../image/workshopbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.workshop .workshop-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.workshop h1 {
  color: #fff;
}

/*....................WorkShopContent.......................*/
.workshopcontent {
  padding: 5rem 0;
}

.workshopcontent h2 {
  font-size: 30px;
}

.workshopcontent .seminar {
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.workshopcontent .seminar-table {
  margin-top: 5rem;
}

.workshopcontent .seminar-table .register-today {
  background-color: #00a5dd;
  color: #fff;
  padding: 8px;
  width: 10rem;
}

.MuiPaper-elevation2.MuiPopover-paper.tss-oeo5eo-MUIDataTableToolbar-filterPaper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  width: 45%;
  left: 50% !important;
}

.MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  padding-left: 14px;
  width: 10%;
}

.workshopcontent .MuiPaper-elevation table thead th {
  color: #fff !important;
  background-color: #f5aa30;
  padding-top: 0;
  padding-bottom: 0;
}

.workshopcontent .MuiPaper-elevation table tbody tr {
  cursor: pointer;
}

.workshopcontent .MuiPaper-elevation table tbody tr td {
  font-weight: 500;
}

.workshopcontent .MuiPaper-elevation table tbody tr td:first-child {
  font-weight: 600;
}

.workshopcontent .MuiPaper-elevation table tbody tr td:nth-child(3) {
  color: #00a5dd;
}


/*................................Registration form....................*/

.registeration-modal form {
  background-color: #f9f9f9;
}

.registeration-modal form .under-div {
  display: flex;
  flex-direction: column;
}

.registeration-modal form input {
  border: 1px solid #dbdbdb;
  padding: 10px;
  padding: 8px 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.registeration-modal form label {
  padding: 0;
  padding-left: 10px;
}

.registeration-modal form .radio h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.registeration-modal form .radio input {
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  margin-left: 2rem;
}

.registeration-modal form select {
  color: var(--text-subtle, #8c8ca1);
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

}

.registeration-modal form button {
  background: #0197d7;
  color: #fff;
  border: none;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding: 10px 0;
}

.registeration-modal form button:hover {
  background: #0386bf;
  color: #fff;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

/*..............................Job Placement............................*/
.jobplacement {
  background-image: url("../image/job-placement.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.jobplacement .placement-content {
  position: relative;
  text-align: center;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.jobplacement .placement-content h1 {
  color: #fff;
}

/*..............................placement content..............................*/
.job-content {
  padding: 5rem 0;
}

.job-content .row {
  border: 1px solid #f1f1f1;
  padding-bottom: 4rem;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.job-content .nav-tabs {
  border-bottom: none;
}

.job-content .top-tab {
  padding: 0;
}

.job-content .top-tab .nav-item.activee button {
  color: #f5aa30;
}

.job-content .top-tab .nav-item.activee,
.job-content .top-tab .nav-item.activee button {
  background-color: #fff;
}

.job-content .top-tab ul li button {
  color: #fff;
  display: grid;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border: none;
}

.job-content .top-tab ul li:first-child {
  border-top-left-radius: 8px;
}

.job-content .top-tab ul li:last-child {
  border-top-right-radius: 8px;
}

.job-content .top-tab ul li button i {
  font-size: 25px;
}

.job-content .top-tab ul li {
  background-color: #00a5dd;
  margin-bottom: 0.7rem;
  width: 16.66%;
}

.job-content .top-tab ul li:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

.job-content .tab-content {
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 4rem;
}

.job-content .tab-content .pane-content {
  gap: 3rem;
}

.job-content .tab-content .tab-pane img {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  height: 300px;
  width: 500px;
}

/*....................................Financial Aid......................................*/
.financial-aid {
  background-image: url("../image/financial_banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  background-position: right;
}

.financial-aid .financial-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
}

.financial-aid .financial-content h1 {
  color: #fff;
}

/*...............................Financial content..................................*/
.finanicial-accor {
  padding: 5rem 0;
}

.finanicial-accor .financial-para {
  gap: 4rem;
}

.finanicial-accor .accordion .accordion-item {
  border-bottom: 1px solid #bebdbd !important;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.finanicial-accor .accordion .accordion-item .accordion-header button {
  background-color: transparent;
  color: #000;
  font-weight: 600;
  box-shadow: none;
  padding: 0;
  padding-bottom: 10px;
}

/*............................................FAQ...........................................*/
.faqbanner {
  background-image: url("../image/FAQbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.faqbanner .faq-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.faqbanner .faq-content h1 {
  color: #fff;
}

.faqcontent {
  padding: 5rem 0;
}

.faqcontent h2 {
  font-size: 30px;
  text-align: center;
}

.faqcontent .accordion .accordion-item {
  border-bottom: 1px solid #bebdbd !important;
  border: none;
  border-radius: 0;
}

.faqcontent .accordion .accordion-item .accordion-body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
}

.faqcontent .accordion .accordion-item .accordion-header button {
  background-color: transparent;
  color: #000;
  font-weight: 600;
  box-shadow: none;
  padding: 0;
  padding-bottom: 10px;
}

/*.......................................Delivery Method........................................*/
.delivery {
  background-image: url("../image/Deliverybanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.delivery .delivery-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.delivery .delivery-content h1 {
  color: #fff;
}

/*...........................................Delivery content......................................*/
.deliverycontent {
  background-color: #f2f2f2;
  padding: 5rem 0;
}

.deliverycontent ul {
  border: none;
}

.deliverycontent ul li {
  margin-bottom: 0.5rem;
}

.deliverycontent ol li {
  font-weight: 600;
}

.deliverycontent ol li p {
  font-size: 1rem;
}

.deliverycontent .delivery_row {
  padding-top: 1rem;
}

.deliverycontent ul li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
}

.deliverycontent .tab-content ul li {
  list-style: disc;
}

/*....................................Delivery Contact...................................*/
.delivery-contact {
  padding: 5rem 0;
}

.delivery-contact h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.delivery-contact form {
  background-color: #ebf0f4;
  width: 60%;
}

.delivery-contact form h3 {
  text-align: center;
}

.delivery-contact form label {
  margin-top: 1rem;
}

.delivery-contact form input[type="text"],
.delivery-contact form input[type="number"],
.delivery-contact form input[type="email"],
.delivery-contact form textarea {
  border: 1px solid #dbdbdb;
  outline: none;
  border-radius: 5px;
  padding: 10px 0 10px 10px;
  width: 100%;
  margin-top: 10px;
}

.delivery-contact form .name {
  gap: 20px;
}

.delivery-contact .delivery-button {
  margin-bottom: 0.5rem;
  margin-top: 2rem !important;
}

/*...........................................Training Solution.......................................*/
.training-banner {
  background-image: url("../image/trainingbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.training-banner .training-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.training-banner .training-content h1 {
  color: #fff;
}

/*.........................................Training content........................................*/
.trainingcontent {
  background-color: #f2f2f2;
  padding: 5rem 0;
}

.training-row {
  margin-top: 1rem;
}


.trainingcontent ul {
  border: none;
}

.trainingcontent ul li {
  margin-bottom: 0.5rem;
}

.trainingcontent img {
  height: 380px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

/*...........................................Student Login............................................*/
.studentbanner {
  background-image: url("../image/Stdentbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.student-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.student-content h1 {
  color: #fff;
}

.student-content p {
  color: #fff;
}

/*.........................................Student-content.........................................*/
.studentcontent {
  padding: 5rem 0;
}

.studentcontent h5 {
  margin-bottom: 0;
}

.studentcontent p {
  padding-right: 10px;
}

.studentcontent .content-div {
  background-color: #ebf0f4;
  border-radius: 10px;
  padding: 45px 30px;
}

.studentcontent button {
  width: 12rem;
}

.studentcontent .request {
  background-color: #00a5dd;
  color: #fff;
  padding: 7px;
  white-space: nowrap;
  width: 12rem;
}

.studentcontent .request:hover {
  background-color: #0392c2;
  color: #fff;
}

.studentcontent .student a {
  color: #fff;
  text-decoration: none;
}

.studentcontent span {
  font-size: 1rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
  padding: 0 10px;
}

/*..........................................Plan your career....................................*/
.planbanner {
  background-image: url("../image/Planbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.plan-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.plan-content h1 {
  color: #fff;
}

.plan-content p {
  color: #fff;
}

/*........................................Plan your content.............................................*/
.plancontent {
  padding: 5rem 0;
}

.plancontent h2 {
  color: #00a5dd;
  font-size: 20px;
}

/*.............................................Corporate Value..............................................*/
.corporatebanner {
  background-image: url("../image/Corporatebanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.corporate-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.corporate-content h1 {
  color: #fff;
}

.corporate-content p {
  color: #fff;
}

/*..........................................Corporate Content.............................................*/
.corporatecontent {
  background-color: #f4f4f4;
  padding: 5rem 0;
}

.corporatecontent h2 {
  margin-bottom: 2rem;
}

.corporatecontent .corporate-row {
  gap: 15px;
}

.corporatecontent .corporate-div {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;
  width: 32%;
}

.corporatecontent img {
  height: 50px;
  width: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  padding-right: 0.8rem;
}

/*...........................................Corporate Strategy..........................................*/
.strategybanner {
  background-image: url("../image/Strategybanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 60vh;
}

.strategy-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.strategy-content h1 {
  color: #fff;
}

.strategy-content p {
  color: #fff;
}

/*......................................Corporate Strategy content...................................*/
.startegycontent {
  /* background-color: #f4f4f4; */
  padding: 5rem 0;
}

.startegycontent .corporate-row {
  gap: 15px;
}

.startegycontent .corporate-div {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;
  width: 32%;
}

.startegycontent img {
  height: 50px;
  width: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  padding-right: 0.8rem;
}

/*.....................................Rent lab.......................................*/
.rentbanner {
  background-image: url("../image/Rentlabbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 60vh;
}

.rent-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.rent-content h1 {
  color: #fff;
}

.rent-content p {
  color: #fff;
}

/*......................................Rent content...................................*/
.rentcontent {
  background-color: #f4f4f4;
  padding: 5rem 0;
}

.rentcontent h2 {
  margin-bottom: 2rem;
}

.labcontent {
  border-radius: 8px;
}

.rentcontent p {
  padding-left: 8px;
}

/*...........................................Career.......................................*/
.careerbanner {
  background-image: url("../image/Careerbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 60%;
}

.career-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.career-content h1 {
  color: #fff;
}

.career-content p {
  color: #fff;
}

/*............................................Career content...............................*/
.careercontent {
  padding: 5rem 0;
}

.careercontent h2 {
  margin-bottom: 2rem;
}

.content-career h2 {
  font-size: 30px;
}

.content-career a {
  color: #00a5dd;
  font-size: 1rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-decoration: none;
}

.content-career img {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/*..........................................web design......................................*/
.webdesignbanner {
  background-image: url("../image/Frame\ 155.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
}

.web-content {
  position: relative;
  top: 40%;
}

.start {
  background-color: #f5aa30;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.start:hover {
  background: #df9a2b;
  color: #fff;
  -webkit-transition: ease-in-out 0.4s;
  -o-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
}



/*............................................Web Service.........................................*/
.webservice {
  padding: 5rem 0;
}

.webservice .card-div {
  gap: 20px;
}

.webservice .card {
  border: none;
  padding: 16px 0 10px 0;
  width: 26rem;
}

.webservice .card img {
  height: 200px;
  width: 200px;
  margin: auto;
}

.webservice .card .card-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #053347;
}

.webservice .card .card-text {
  text-align: center;
  color: #053347;
  font-weight: 500;
}

/*.....................................why global it web design.............................................*/
.webwhy {
  padding-bottom: 5rem;
}

.webwhy h2 {
  color: #053347;
}

.webwhy p {
  color: #053347;
  font-weight: 400;
}


/*......................................web design contact.................................................*/
.web-contact {
  padding: 5rem 0;
  background-image: url('../image/Background\ 3\ 1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.web-contact form {
  background-color: #ebf0f4;
  padding: 30px 40px;
}

.web-contact form h3 {
  color: #000;
  text-align: left;
}

.web-contact form input[type=text],
.web-contact form input[type=email],
.web-contact form input[type=number],
.web-contact form textarea {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-top: 10px;
  outline: none;
  padding: 10px 0 10px 10px;
  width: 100%;
}

.web-contact .web-message {
  width: 67.5%;
}

.web-contact .web-button {
  width: 33%;
  height: 68px;
  margin-top: 0.7rem;
}


/*.......................................web design portfolio...............................................*/
.webportfolio {
  padding-bottom: 5rem;
}

.webportfolio h2 {
  text-align: center;

  margin-bottom: 2.5rem;
}

.webportfolio img {
  width: 100%;
}

/*............................................Staff services................................................*/
.staffbanner {
  background-image: url("../image/staffingbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
}

.staff-content {
  position: relative;
  top: 45%;
}

.staff-content h1 {
  color: #fff;
}

.staff-content p {
  color: #fff;
}

/*............................................Staff Content...........................................*/
.staffcontent {
  padding: 5rem 0;
}

.staffcontent h3 {
  text-align: center;
  color: #053347;
}

.staffpost p {
  margin-bottom: 0;
}

.staffcontent {
  padding-bottom: 2rem;
}

.staffcontent .card {
  border: none;
  height: 450px;
  transition: 0.5 ease;
  -webkit-transition: 0.5 ease;
  -moz-transition: 0.5 ease;
  -ms-transition: 0.5 ease;
  -o-transition: 0.5 ease;
  overflow: hidden;
  box-shadow: 0px 9px 33px -23px #161C2D21;
}

.staffcontent .card img {
  height: 60%;
  object-fit: cover;
}

.staffcontent .card .card-title {
  color: #161C2D;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
}



/*......................................staff accordian image...................................*/
.staffaccorimg {
  padding: 5rem 0;
}

.staffaccorimg h2 {
  color: #053347;
}

.staff-accor {
  margin-top: 5rem;
}

.staffaccorimg p {
  padding-left: 8px;
}

.staffaccorimg .accordion-item {
  background-color: #ebf0f4;
  margin-bottom: 1rem;
  border: 2px solid #ececec;
  border-radius: 5px;
}

.staffaccorimg .accordion-item button {
  background-color: #ebf0f4;
  color: #000;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
}

.staffaccorimg .accordion-body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
}

/*................................Network Service....................................*/
.networkbanner {
  background-image: url("../image/Frame\ 154-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.networkbanner .netimg {
  position: absolute;
  right: 9rem;
  width: 30%;
  top: 8rem;
}

.network-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.network-content h1 {
  color: #fff;
}

.network-content p {
  color: #fff;
}

/*..........................................Network Content.........................................*/
.networkcontent,
.netaccor {
  padding-top: 5rem;
}

.networkdesign {
  padding: 5rem 0;
}

.netcol {
  background-color: #FAFAFA;
}

.networkcontent h3 {
  color: #053347;
  text-align: center;
}

.networkcontent .card {
  border-radius: 10px;
  border: none;
  text-align: center;
  width: 23%;
  box-shadow: 2px 2px 27.8px 0px #00000026;
}

.networkcontent .card-loop {
  gap: 25px;
}

.networkcontent .card .card-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
}

.networkcontent h2 {
  color: #053347;
  margin-top: 3rem;
}

.networkcontent p {
  color: #053347;
}

/*-----------------------network accordian----------------------*/
.networkaccordian {
  padding-top: 5rem;
}

.networkaccordian .accordion-item {
  background-color: #ebf0f4;
  margin-bottom: 1rem;
  border: 2px solid #ececec;
  border-radius: 5px;
}

.networkaccordian .accordion-item h2 {
  font-size: 18px;
}

.networkaccordian .accordion-item button {
  background-color: #ebf0f4;
  color: #000;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
}

.networkaccordian .accordion-body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
}

/*..............................................Our Partner..............................................*/
.partnerbanner {
  background-image: url("../image/partnerbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.partner-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.partner-content h1 {
  color: #fff;
}

.partner-content p {
  color: #fff;
}

/*................................................Partner content...............................................*/
.partnercontent {
  background-color: #f4f4f4;
  padding: 5rem 0;
}

.partnercontent h3 {
  color: #00a5dd;
  font-size: 25px;
  margin-top: 2rem;
}

.partnercontent img {
  margin-right: 2rem;
  height: 50px;
  margin-top: 1rem;
}

.partnercontent .partner-gap {
  column-gap: 50px;
}

/*..............................................Overview (About us).........................................*/
.overviewbanner {
  background-image: url("../image/overviewbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.overview-content {
  position: relative;
  top: 60%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.overview-content h1 {
  color: #fff;
}

.overview-content p {
  color: #fff;
}

/*............................................Overview Content...............................................*/
.overviewcontent {
  padding: 5rem 0;
}

.overviewcontent img {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.overviewcontent .container {
  row-gap: 2rem;
}

.overviewcontent .overview_row {
  margin-top: 2rem;
}

/*..................................Book Tour........................*/
.booktourbanner {
  background-image: url("../image/appoinmentbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.booktourbanner .book-content {
  text-align: center;
  position: relative;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.booktourbanner .book-content h1 {
  color: #fff;
}

/*....................................Book Content..............................*/
.bookcontent {
  padding: 5rem 0;
}

.bookcontent h2,
.bookcontent .book-para {
  text-align: center;
}

.bookcontent form {
  background-color: #f9f9f9;
  width: 60%;
}

/*......................................Register modal..................................*/
.registermodal .modal-content {
  left: 90%;
  animation: bounce-in-right 1s ease;
  -webkit-animation: bounce-in-right 1s ease;
  background: transparent;
}

.registermodal .close {
  display: none;
}

.register-modal form {
  background-color: #f9f9f9;
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}


/*.........................Student access.......................*/
.request-banner {
  background-image: url('../image/student-access.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.request-banner .request-content {
  position: relative;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  text-align: center;
}

.request-banner .request-content h1,
.request-banner .request-content p {
  color: #fff;
}


/*.......................Request form........................*/

.request-form form {
  background-color: #f9f9f9;
}

.request-form form .under-div {
  display: flex;
  flex-direction: column;
}

.request-form form input,
.request-form form select {
  border: 1px solid #dbdbdb;
  padding: 10px;
  padding: 8px 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.request-form form select {
  color: var(--text-subtle, #8c8ca1);
  border: 1px solid #dbdbdb;
  padding: 10px 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.request-form form button {
  background: #0197d7;
  color: #fff;
  border: none;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding: 10px 0;
}

.request-form form button:hover {
  background: #0386bf;
  color: #fff;
  -webkit-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

/*.........................Refrral Program.........................*/
.refrrall-banner {
  background-image: url(../image/Stdentbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}

.referral-content {
  top: 60%;
  position: relative;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.referral-content h1,
.referral-content p {
  color: #fff;
}

/*...........................Refrral Content......................*/
.refrralcontent {
  padding: 5rem 0;
}

.refrralcontent h2 {
  margin-bottom: 2rem;
}

.refrralcontent .refrallhead h2,
.refrralcontent .refrallhead p {
  text-align: center;
}

.refrralcontent form {
  background: #f9f9f9;
}


/*................................Free Career Night..........................*/
.freenigthbanner {
  background-image: url('../image/staffbanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.freenight-content {
  position: relative;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.freenight-content h1,
.freenight-content p {
  color: #fff;
}

/*................................free cereer night content.....................*/
.freenightcontent {
  padding: 5rem 0;
}

.freenightcontent form {
  background: #f9f9f9;
}

.freenightcontent form input {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 10px;
  padding: 10px 10px;
  width: 100%;
}

/*...........................Exam Registration........................*/
.examregisbanner {
  background-image: url('../image/Stdentbanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}

.examregis-content {
  position: relative;
  top: 60%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.examregis-content h1,
.examregis-content p {
  color: #fff;
}

/*............................Exam Registartion content..................*/
.examregiscontent {
  padding: 5rem 0;
}

.examregiscontent form {
  background: #f9f9f9;
}

.searchresult {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 25px;
}

.searchresult .card img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.searchresult .card {
  text-align: center;
  box-shadow: none;
}

.searchpage {
  padding: 8rem 0;
}

.searchpage .card-image {
  padding: 20px;
}

.searchpage .search-icon {
  width: 70%;
  margin: 10px auto;
  border: 2px solid #000;
  border-radius: 5px;
}

.searchresult .card button {
  color: #1da7f5;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.36px;
}

.searchresult .card button:hover {
  color: #F5AA30;
}